import { Component } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';
import { UnitSystemsService } from 'src/app/core/services/unitsystems.service';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { INTERVAL_TIMES, UNITS } from 'src/app/shared/enums/enums';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/app/core/services/system.service';
import { startWith, switchMap } from 'rxjs/operators';
import { LocalTokenService } from 'src/app/core/services/localtoken.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public unitsystem: string;
  public unitsystemlabel: string;
  public joblabel: string;
  public globallabel: string;
  //public hideDashboard: boolean = false;
  //public isOnline: boolean = false;
  public isLocal: boolean = false;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public _localauth: LocalTokenService,
    public _unitConversion: UnitConversionService,
    public _unitSystems: UnitSystemsService,
    public _utilities: UtilitiesService,
    public _system: SystemService
  ) {
  }

  ngOnInit() {
    this.globallabel = this._utilities.getTranslationNow('layout.globalcurrentUnitSystemLabel');
    this.joblabel = this._utilities.getTranslationNow('layout.currentUnitSystemLabel');
    this._unitConversion.currentUnitSystem$.subscribe(
      result => {
        this.unitsystemlabel = this.globallabel;
        if(this._unitSystems.globalUomProfile$.getValue() == 'job') {
          this.unitsystem = this._utilities.getTranslationNow('settings.uom_job_preferred');
        } else {
          this.unitsystem = this._unitSystems.globalUnitSystem
          ? this._unitSystems.globalUnitSystem.name + this._utilities.getTranslationNow('settings.uom_user_preferred')
          : UNITS.API;

          if (result && this._unitSystems.isJobUnitSystem) {
            this.unitsystemlabel = this.joblabel;
            this.unitsystem = result.name + this._utilities.getTranslationNow('settings.uom_user_preferred');
          }
        }
      },
      (err) => {
      }
    );

    this._unitSystems.globalUomProfile$.subscribe(
      result => {
        if(result && result != '') {
          this.unitsystem = result == 'job'
            ? this._utilities.getTranslationNow('settings.uom_job_preferred')
            : this._unitSystems.globalUnitSystem.name + this._utilities.getTranslationNow('settings.uom_user_preferred');
        }
    });

    this.isLocal = environment.local ? true : false;
    if (this.isLocal) {
      interval(INTERVAL_TIMES.TokenRefresh)
        .pipe(
          switchMap(() => this._localauth.getTokenGenerator(true))
        )
        .subscribe(res => {
          res;
        },
          error => {
          });

      interval(INTERVAL_TIMES.NetworkStatus)
        .pipe(
          startWith(0),
          switchMap(() => this._system.checkConnection())
        )
        .subscribe();
    };

  };

  get isOnInternet(): boolean {
    return environment.web.isOnInternet;
  }

  get isOnNetwork(): boolean {
    return environment.web.isOnNetwork;
  }

}
