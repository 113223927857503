export class JobDetails {
  jobId: string;
  jobName: string;
  jobTypes: string[];
  jobCustomerType: string;
  jobPurpose: string;
  jobStatus: string;
  rigName: string;
  wellName: string;
  spudDate: string;
  defaultUnitProfile: string;
  constructor() {
      this.jobId = null;
      this.jobName = null;
      this.jobTypes = null;
      this.jobCustomerType = null;
      this.jobPurpose = null;
      this.jobStatus = null;
      this.rigName = null;
      this.wellName = null;
      this.spudDate = null;
  }
}

export class JobDashboard {
  public jobName: string
  public jobTypes: string[]
  public jobCustomerType: string
  public jobPurpose: string
  public jobStatus: string
  public rigName: string
  public wellName: string
  public spudDate: string
  public jobOwner: string;
  public operator: string;
  public signal: string;
  public rigTime: string;
  public country: string;
  public region: string;
  public cuttings: number;
  public alerts: Alert[] | string;
  public alert: string;
  public activity: string;
  public md: number;
  public timezone: string;

    constructor(data) {
      this.jobName = data && data.jobName ? data.jobName : '';
      this.jobTypes = data && data.jobTypes ? data.jobTypes : [];
      this.jobCustomerType = data && data.jobCustomerType ? data.jobCustomerType : '';
      this.jobPurpose = data && data.jobPurpose ? data.jobPurpose : '';
      this.jobStatus = data && data.jobStatus ? data.jobStatus : '';
      this.rigName = data && data.rigName ? data.rigName : '';
      this.wellName = data && data.wellName ? data.wellName : '';
      this.spudDate = data && data.spudDate ? data.spudDate : '';
      this.jobOwner = data && data.jobOwner ? data.jobOwner : '';
      this.region = data && data.region ? data.region : '';
      this.country = data && data.country ? data.country : '';
      this.timezone = data && data.timezone ? data.timezone : ''
    }
}

export class SalesDetails {
  salesInformationId: string;
  salesOfficeAddress: string;
  salesOfficePhone: string;
  warehouseAddress: string;
  warehousePhone: string;
  jobId: string;
  constructor() {
      this.salesInformationId = null;
      this.salesOfficeAddress = null;
      this.salesOfficePhone = null;
      this.warehouseAddress = null;
      this.warehousePhone = null;
      this.jobId = null;
  }
}

export class Jobs
{
  jobs:Job[];
}

export class Job {
  id: string;
  jobId: string;
  name: string;
  location: string;
  state: string;
  country: string;
  type: string;
  rigName: string;
  jobStatus: string;
  activity: string;
  md: string;
  purpose: string;
  rigTime: string;
  operator: string;
  signal: number;
  wellName: string;
  phase: string;
  statusPosition: string;
  progressBarPercentage: number;
  cuttings: number;
  alerts: Alert[];
}

export class Alert {
  idx: string;
  status: string;
  message: string;
}

export class apiDataModel{
  constructor(
    public  columnSettings: Array<any>,
    public  dataRows: Array<any>,
  ){}
}

